<template>
  <div class="container">
    <header-back name='收支明细'>
      <template slot="seachRight">
        <span @click="onExport"> 下载
          <van-icon name="down" />
        </span>
      </template>
    </header-back>
    <section class="top44">
      <div class="revenue-date">
        <van-field
          name="radio"
          label="状态"
        >
          <template #input>
            <van-radio-group
              v-model="radio"
              direction="horizontal"
            >
              <van-radio
                icon-size="16px"
                checked-color="#94FF75"
                v-for="(item,index) in radioList"
                :key='index'
                :name="item.name"
              >{{item.value}}</van-radio>

            </van-radio-group>
          </template>
        </van-field>
        <!-- <van-field
          readonly
          clickable
          name="datetimePicker"
          :value="value"
          label="选择日期"
          placeholder="点击选择时间"
          @click="showPicker = true"
        />
        <van-popup
          v-model="showPicker"
          position="bottom"
        >
          <van-datetime-picker
            type="date"
            v-model="currentDate"
            :formatter="formatter"
            @confirm="onConfirm"
            @cancel="showPicker = false"
          />
        </van-popup> -->

        <van-field
          readonly
          name="calendar"
          :value="value"
          label="开始/结束日期"
          label-width='7em'
          placeholder="选择日期区间"
          @click="showCalendar = true"
        >
          <template
            #right-icon
            v-if="value"
          >
            <van-icon
              name="clear"
              color="#c8c9cc"
              @click.stop="onClearable"
            />
          </template>

        </van-field>
        <van-calendar
          type="range"
          :min-date="minDate"
          :color="variables.textColor"
          allow-same-day
          v-model="showCalendar"
          @confirm="onConfirmCan"
        >

        </van-calendar>
        <div class="div-btn">
          <van-button
            round
            type="primary"
            :color="variables.textColor"
            size="small"
            @click="onSave"
          >确定</van-button>
        </div>
      </div>
      <div
        class="revenue-cen"
        v-if="orderCount"
      >
        <!-- <div class="tit-l">
          <p>收入: <span class="tit-red">￥{{money1}}</span></p>
          <div>订单数量:{{orderNum}}</div>
        </div>
        <div class="tit-r">
          <p>扣款: <span class="tit-red">￥{{money1}}</span></p>
          <div>订单数量：{{orderNum}}</div>
        </div> -->
        <!-- <div class="tit-l">
          <p>收入</p>
          <div class="tit-red">￥{{money1}}</div>
        </div>
        <div class="tit-r">
          <p>订单数量</p>
          <div>{{orderNum}}</div>
        </div> -->
        <div class="tit-l">
          <p>做单收入：<span class="tit-red">￥{{orderCount.sumFlowAmount || 0}}</span></p>
        </div>
        <div class="tit-r">
          <p>订单数量：{{orderCount.countOrder || 0}}</p>
        </div>
      </div>
      <div
        class="revenue-cen"
        v-if="fineCount"
      >
        <div class="tit-l">
          <p>平台罚款：<span class="tit-red">￥{{fineCount.sumFlowAmount || 0}}</span></p>
        </div>
        <div class="tit-r">
          <p>订单数量：{{fineCount.countOrder || 0}}</p>
        </div>
      </div>
      <div
        class="revenue-cen"
        v-if="cancelCount"
      >
        <div class="tit-l">
          <p>平台撤单：<span class="tit-red">￥{{cancelCount.sumFlowAmount || 0}}</span></p>
        </div>
        <div class="tit-r">
          <p>订单数量：{{cancelCount.countOrder || 0}}</p>
        </div>
      </div>
      <div
        class="revenue-cen"
        v-if="editCount"
      >
        <div class="tit-l">
          <p>平台调账：<span class="tit-red">￥{{editCount.sumFlowAmount || 0}}</span></p>
        </div>
        <div class="tit-r">
          <p>订单数量：{{editCount.countOrder || 0}}</p>
        </div>
      </div>
      <div class="revenue-main">
        <div class="cen-h">明细</div>
        <div class="cen-m">
          <van-pull-refresh
            v-model="refreshing"
            style="min-height:100vh"
            @refresh="onRefresh"
          >
            <van-list
              v-model="loading"
              :finished="finished"
              finished-text="没有更多了"
              @load="onLoad"
            >
              <div v-if="orderList.length > 0">
                <ul
                  class="cen-m-ul"
                  v-for="(item,index) in orderList"
                  :key='index'
                >
                  <div v-if="item.type===1">
                    <li>
                      <div>类型：<span>{{item.type | businessType}}</span></div>
                      <div><span>{{item.name}}</span></div>
                    </li>
                    <!-- <li>扣款前余额：<span class="red-color">￥{{item.money}}</span></li>
                    <li>扣款后余额：<span class="red-color">￥{{item.num}}</span></li> -->
                    <li>罚款原因：<span>{{item.fineReason}}</span></li>
                    <li>罚款金额：<span class="red-color">￥{{item.flowAmount}}</span></li>
                    <li>号码：<span>{{item.orderMobile}}</span></li>
                    <li>订单号：<span>{{item.orderSn}}</span></li>
                    <!-- <li>积分：<span>{{item.num}}</span></li> -->
                    <li>时间：<span>{{item.addTime}}</span></li>
                  </div>
                  <div v-if="item.type===2">
                    <li>
                      <div>类型：<span>{{item.type | businessType}}</span></div>
                      <div><span>{{item.name}}</span></div>
                    </li>
                    <!-- <li>提现前余额:<span class="red-color">￥{{item.money}}</span></li> -->
                    <li>撤单原因：<span>{{item.cancelReason}}</span></li>
                    <li>撤单金额：<span class="red-color">￥{{item.flowAmount}}</span></li>
                    <li>状态：<span>{{item.cancelStatus=== 0 ? '撤单' : '取消撤单'}}</span></li>
                    <!-- <li>提现后余额：<span class="red-color">{{item.num}}</span></li>  -->
                    <li>号码：<span>{{item.orderMobile}}</span></li>
                    <li>订单号：<span>{{item.orderSn}}</span></li>
                    <li>时间：<span>{{item.addTime}}</span></li>
                  </div>
                  <div v-if="item.type===3">
                    <li>
                      <div>类型：<span>{{item.type | businessType}}</span></div>
                      <div><span>{{item.isFreeze ? '冻结金额' : '可提现金额'}}</span></div>
                    </li>
                    <!-- <li>提现前余额:<span class="red-color">￥{{item.money}}</span></li> -->
                    <!-- <li>调账原因：<span></span></li> -->
                    <!-- <li>调账类型：<span>{{item.isFreeze ? '冻结金额' : '可提现金额'}}</span></li> -->
                    <li>调账金额：<span class="red-color">￥{{item.flowAmount}}</span></li>
                    <!-- <li>提现后余额：<span class="red-color">{{item.num}}</span></li> -->
                    <!-- <li>号码：<span class="red-color">{{item.bankNum}}</span></li> -->
                    <li>时间：<span>{{item.addTime}}</span></li>
                  </div>
                  <div v-if="item.type===4">
                    <li>
                      <div>类型：<span>{{item.type | businessType}}</span></div>
                      <div><span>{{item.name}}</span></div>
                    </li>
                    <!-- <li>回款前余额：<span class="red-color">￥{{item.money}}</span></li>
                    <li>回款后余额：<span class="red-color">￥{{item.num}}</span></li> -->
                    <!-- <li>折扣：<span class="red-color">{{item.num}}</span></li> -->
                    <li>金额：<span class="red-color">+ {{item.flowAmount}}</span></li>
                    <li>号码：<span>{{item.orderMobile}}</span></li>
                    <li>订单号：<span>{{item.orderSn}}</span></li>
                    <!-- <li>积分：<span>{{item.num}}</span></li> -->
                    <li>时间：<span>{{item.addTime}}</span></li>
                  </div>
                </ul>
              </div>
            </van-list>
          </van-pull-refresh>
          <!-- <div
            class="cen-no-ul"
            v-else
          >暂无数据</div> -->
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import HeaderBack from "../../components/header-back";
import { Toast } from "vant";
import utils from "../../utils/index.js";
import variables from "@/styles/variables.scss";
import API from "../../api/http";
export default {
  components: { HeaderBack },
  data() {
    return {
      showPicker: false,
      showCalendar: false,
      value: "",
      currentDate: new Date(),
      orderCount: "",
      fineCount: "",
      cancelCount: "",
      editCount: "",
      platformDeductions: "",
      withdrawCount: "",

      orderList: [],
      radio: "0",
      radioList: [
        {
          value: "全部",
          name: "0",
        },
        // {
        //   value: "提现记录",
        //   name: "1",
        // },
        {
          value: "平台罚款",
          name: "1",
        },
        {
          value: "平台撤单",
          name: "2",
        },
        {
          value: "平台调账",
          name: "3",
        },
        // {
        //   value: "平台撤单",
        //   name: "4",
        // },
        {
          value: "做单收入",
          name: "4",
        },
      ],
      startDate: "", //开始日期
      endDate: "", //结束日期
      minDate: new Date(2010, 0, 1),

      listQuery: {
        page: 1,
        type: 0,
      },
      loading: false,
      finished: false,
      refreshing: false,
    };
  },
  computed: {
    variables() {
      return variables;
    },
  },
  created() {
    this.setIsDate();
    this.onLoad();
  },
  methods: {
    onConfirm(value) {
      this.value = utils.parseTime(value, "{y}-{m}-{d}");
      this.showPicker = false;
    },
    formatter(type, val) {
      if (type === "year") {
        return `${val}年`;
      } else if (type === "month") {
        return `${val}月`;
      } else if (type === "day") {
        return `${val}日`;
      }
      return val;
    },
    formatDate(date) {
      return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
    },

    onConfirmCan(date) {
      const [start, end] = date;
      this.showCalendar = false;

      this.startDate = utils.parseTime(start, "{y}-{m}-{d}") + " " + "00:00:00";

      this.endDate = utils.parseTime(end, "{y}-{m}-{d}") + " " + "23:59:59";

      this.value = `${this.formatDate(start)} - ${this.formatDate(end)}`;
    },
    setIsDate() {
      let start = new Date();
      let end = new Date();
      this.startDate = utils.parseTime(start, "{y}-{m}-{d}") + " " + "00:00:00";
      this.endDate = utils.parseTime(end, "{y}-{m}-{d}") + " " + "23:59:59";
      this.value = `${this.formatDate(start)} - ${this.formatDate(end)}`;
      this.listQuery.startDate = this.startDate;
      this.listQuery.endDate = this.endDate;
    },
    onClearable() {
      this.value = "";
      this.startDate = "";
      this.endDate = "";
    },
    onSave() {
      //确定
      let obj = {
        page: 1,
        type: Number(this.radio),
      };

      if (this.startDate) {
        obj.startDate = this.startDate;
        obj.endDate = this.endDate;
      }
      this.listQuery = obj;

      this.onRefresh();
    },
    onLoad() {
      if (this.refreshing) {
        this.orderList = [];
        this.refreshing = false;
      }
      console.log("onLoad");
      this.loading = true;
      this.getList();
    },
    onRefresh() {
      //下拉刷星
      // 清空列表数据

      this.finished = false;
      this.refreshing = true;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.listQuery.page = 1;
      this.orderList = [];
      this.getList(1);
    },
    getList(val) {
      API.getFlowList(this.listQuery)
        .then((res) => {
          let data = res.data;
          this.loading = false;
          if (data.page === 1) {
            this.orderCount = "";
            this.fineCount = "";
            this.cancelCount = "";
            this.editCount = "";
            if (data.orderCount && JSON.stringify(data.orderCount) !== "{}") {
              this.orderCount = data.orderCount;
            }

            if (data.fineCount && JSON.stringify(data.fineCount) !== "{}") {
              this.fineCount = data.fineCount;
            }
            if (data.cancelCount && JSON.stringify(data.cancelCount) !== "{}") {
              this.cancelCount = data.cancelCount;
            }
            if (data.editCount && JSON.stringify(data.editCount) !== "{}") {
              this.editCount = data.editCount;
            }
          }

          if (data.list.length > 0) {
            let list = data.list;

            if (data.pages === 1) {
              this.finished = true;
            }

            if (val) {
              this.refreshing = false;

              this.orderList = list;
            } else {
              this.orderList = this.orderList.concat(list);
            }

            this.listQuery.page++;
          } else {
            this.refreshing = false;
            this.finished = true;
          }
        })
        .catch(() => {
          this.refreshing = false;
          this.loading = false;
          this.finished = true;
        });
    },
    onExport() {
      //导出
      let data = {
        status: this.radio,
      };
      if (this.listQuery.startDate) {
        data.startDate = this.listQuery.startDate;
        data.endDate = this.listQuery.endDate;
      }

      API.exportFlowEXpotr(data, "收支明细");
    },
  },
};
</script>
<style lang='scss' scoped>
@import "../../styles/variables.scss";
.container {
  padding: 16px 15px;
}
.revenue-cen {
  background-color: #fff;
  padding: 16px;
  margin-top: 16px;
  border-radius: 6px;
  font-size: 14px;
  color: #515151;
  display: flex;
  & > div {
    flex: 1;
    & > div {
      font-weight: 500;
      line-height: 28px;
    }
  }
  .tit-r {
    padding-left: 30px;
  }
  .tit-red {
    color: $redColor;
  }
}
.revenue-main {
  margin-top: 16px;

  font-size: 14px;
  .cen-h {
    line-height: 28px;
    padding-bottom: 8px;
    padding-left: 16px;
  }
  .cen-m-ul {
    overflow: hidden;
    background-color: #fff;
    border-radius: 6px;
    padding: 16px;
    box-sizing: border-box;
    margin-bottom: 10px;
    li {
      display: flex;
      & > div {
        flex: 1;
      }
      & > div:last-child {
        text-align: right;
      }
    }
  }

  .cen-no-ul {
    text-align: center;
    padding: 16px 0;
  }
}
.cen-m {
  width: 100%;

  li {
    color: #0a0a0a;
    line-height: 26px;
    span {
      color: #595959;
    }
    span.red-color {
      color: $redColor;
    }
  }
}
.revenue-date {
  background: #fff;
  border-radius: 6px;
  overflow: hidden;
  .div-btn {
    text-align: center;
    padding: 10px 0;
    .van-button {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
  .van-radio {
    padding-bottom: 8px;
  }
}
</style>
